import React, { useState, useEffect } from "react";

import SideNav from "../../../components/adminqu/Sidenav";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaPlus, FaUser } from "react-icons/fa";

import Api from "../../../services/Api";

function AdminquOrders() {
  const [isLoading, setIsLoading] = useState(false);

  const [ordersOriginal, setOrdersOriginal] = useState([]);
  const [orders, setOrders] = useState([]);

  const [search, setSearch] = useState("");
  const [isCanceling, setIsCanceling] = useState(false);
  const [canceledOrder, setCanceledOrder] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("all");

  const defineStatus = {
    pending: { label: "Menunggu Konfirmasi", color: "bg-yellow-100" },
    process: { label: "Dalam Proses", color: "bg-blue-100" },
    delivery: { label: "Dalam Pengiriman", color: "bg-purple-100" },
    completed: { label: "Selesai", color: "bg-green-100" },
    cancel: { label: "Dibatalkan", color: "bg-red-100" },
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const costCorrection = async (orderData) => {
    var subtotalCost = 0;

    orderData.items.map((item) => {
      if (item.catalog_variant_id != null) {
        subtotalCost += item.catalog_variant_id.cost * item.qty;
      } else {
        subtotalCost += item.catalog_id.cost * item.qty;
      }
    });

    const postData = {
      subtotal_cost: subtotalCost,
    };

    // update to API
    const token = localStorage.getItem("token");
    Api.patch("/items/Orders/" + orderData.id, postData, token)
      .then((response) => {
        //console.log(response);
        console.log("Cost updated", response);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getInitialOrders = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    var queryUrl = "/items/Orders?fields=*.*.*&sort=-date_created";

    if (selectedStatus !== "all") {
      queryUrl += "&filter[status][_eq]=" + selectedStatus;
    }

    Api.get(queryUrl, token)
      .then((response) => {
        if (response) {
          setOrdersOriginal(response.data);

          response.data.map((item) => {
            costCorrection(item);
          });

          setOrders(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getInitialOrders();
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleString("id-ID", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatCurrency = (price) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(price);
  };

  useEffect(() => {
    if (search) {
      const filteredData = ordersOriginal.filter((item) => {
        return item.order_number.toLowerCase().includes(search.toLowerCase());
      });
      setOrders(filteredData);
    } else {
      setOrders(ordersOriginal);
    }
  }, [search]);

  useEffect(() => {
    getInitialOrders();
  }, [selectedStatus]);

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <SideNav page="orders" />
        </div>
        <div className="flex-1 flex justify-center items-center p-4 py-5">
          <div className="w-full">
            <div className="fixed top-14 justify-center items-center md:right-0 px-8 md:w-96 w-80 sm:top-5">
              <div className="px-2 py-2 shadow bg-white rounded-lg">
                <div className="flex flex-row gap-4 items-center">
                  <div className="flex w-full flex-row">
                    <input
                      type="text"
                      placeholder={
                        "Cari pesanan dari " + orders.length + " data"
                      }
                      className="border-2 w-full border-gray-100 bg-gray-100 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:bg-white"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <button className="right-6 top-5 text-pink-500 -ml-8">
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 font-bold">Pesanan</div>

            <div className="pt-24 md:pt-20 lg:pt-16 w-full">
              {isLoading ? (
                <div className="flex justify-center h-32 items-center">
                  <ReactLoading
                    type={"balls"}
                    color={"gray"}
                    height={48}
                    width={48}
                  />
                </div>
              ) : (
                <div className="container mx-auto">
                  <div className="flex flex-row justify-center">
                    <div className="flex-1 flex flex-wrap w-3/4 gap-2 mb-4 overflow-x-scroll justify-center ">
                      <button
                        onClick={() => setSelectedStatus("all")}
                        className={
                          selectedStatus == "all"
                            ? "bg-gray-100 text-gray-500 px-2 py-1 rounded-lg text-xs border border-gray-600 m-2"
                            : "bg-gray-100 text-gray-500 px-2 py-1 rounded-lg text-xs m-2"
                        }>
                        Semua
                      </button>
                      {defineStatus &&
                        Object.keys(defineStatus).map((key, index) => (
                          <button
                            onClick={() => setSelectedStatus(key)}
                            key={index}
                            className={
                              selectedStatus == key
                                ? defineStatus[key].color +
                                  " text-gray-500 px-2 py-1 rounded-lg text-xs border border-gray-600"
                                : defineStatus[key].color +
                                  " text-gray-500 px-2 py-1 rounded-lg text-xs"
                            }>
                            {defineStatus[key].label}
                          </button>
                        ))}
                    </div>
                  </div>

                  <div className="flex-1 px-4 lg:px-12 flex flex-row  justify-center">
                    <div className="flex flex-col gap-2 w-full ">
                      {/* loop dfine status */}

                      {orders.map((item, index) => (
                        <Link
                          to={"/adminqu/orders/" + item.id}
                          className="p-2 bg-gray-50 ">
                          <div className="flex flex-col lg:justify-between lg:flex-row text-sm gap-1 mb-2 ">
                            <div className="flex-1 font-semibold">
                              {item.order_number}
                            </div>
                            <div className="flex-1 lg:text-right">
                              {formatDate(item.date_created)}
                            </div>
                          </div>
                          <div>
                            <div className="flex flex-row text-gray-500 text-sm items-center gap-2">
                              <FaUser className="inline text-xs" />{" "}
                              {item.customer_id.first_name}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between items-center mt-2">
                            <span className="text-md text-xs font-bold text-gray-800 ">
                              {formatCurrency(item.total)}
                            </span>
                            <span
                              className={
                                defineStatus[item.status].color +
                                " px-2 py-1 rounded-lg text-xs"
                              }>
                              {defineStatus[item.status].label}
                            </span>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white rounded-lg p-8 z-50 mx-4">
                  <p>Apakah anda yakin ingin menghapus brand ini?</p>
                  <p className="font-semibold">{canceledOrder.name}</p>

                  <div className="flex gap-2 flex-row justify-between">
                    <button
                      onClick={closeModal}
                      className="mt-4 bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded">
                      Batal
                    </button>
                    {isCanceling ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        onClick={() => {
                          // handleDelete();
                        }}
                        className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Ya, hapus
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            <ToastContainer />
          </div>
        </div>
        <Link
          className="invisible lg:visible fixed bottom-4 right-10 bg-teal-500 hover:bg-teal-700 text-white font-bold py-5 px-5 rounded-full text-sm  h-12 items-center shadow flex gap-2 flex-row"
          to="/adminqu/orders/add">
          <FaPlus /> <span>Tambah pesanan</span>
        </Link>
        <Link
          className="lg:invisible visible fixed bottom-4 right-4 bg-teal-500 hover:bg-teal-700 text-white font-bold py-5 px-5 rounded-full w-14 h-14 items-center shadow"
          to="/adminqu/orders/add">
          <FaPlus />
        </Link>
      </div>
    </>
  );
}

export default AdminquOrders;
