import React from "react";
import { useState, useEffect } from "react";

import SideNav from "../../../components/adminqu/Sidenav";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import { FaSearch, FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

import Api from "../../../services/Api";

function AdminquOrderDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [isOpenModalStatus, setIsOpenModalStatus] = useState(false);

  const defineStatus = {
    pending: "Menunggu Konfirmasi",
    process: "Dalam Proses",
    delivery: "Dalam Pengiriman",
    completed: "Selesai",
    cancel: "Dibatalkan",
  };

  const getOrder = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const id = window.location.href.split("/").pop();

    Api.get(`/items/Orders/${id}?fields=*.*.*`, token)
      .then((response) => {
        if (response) {
          setOrder(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOrder();
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleString("id-ID", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatCurrency = (price) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(price);
  };

  const changeStatus = async (status) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const id = window.location.href.split("/").pop();

    Api.patch(
      `/items/Orders/${id}`,
      {
        status: status,
      },
      token
    )
      .then((response) => {
        if (response) {
          toast.success("Status Pesanan berhasil diubah");
          if (status == "cancel") {
            // update stock
            order.items.map((item) => {
              if (item.catalog_variant_id) {
                Api.post(
                  "/flows/trigger/51b13ddb-8fd1-4b0a-99b1-8bea3aa6e918",
                  {
                    id: item.catalog_variant_id.id,
                    qty: item.qty,
                  },
                  token
                )
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                Api.post(
                  "/flows/trigger/3d76ccf5-3ed0-463a-bdf4-a738dba0e126",
                  {
                    id: item.catalog_id.id,
                    qty: item.qty,
                  },
                  token
                )
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            });
          }

          getOrder();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-row w-full">
      <div className="lg:w-56 w-[3.35rem] ">
        <SideNav page="orders" />
      </div>
      <div className="flex-1">
        <div>
          <div className="fixed w-full ml-0 mt-0 px-4 py-3 shadow bg-pink-500 text-white z-50">
            <div className="flex flex-row justify-between items-center">
              <div className="w-8">
                <Link to="/adminqu/orders">
                  <FaChevronLeft className="text-white" />
                </Link>
              </div>
              <div className="flex-1">
                <div className="text-sm md:text-lg font-bold text-left">
                  Pesanan - {order.order_number}
                </div>
              </div>
              <div className="w-1/5 text-right"></div>
            </div>
          </div>

          <div className="flex flex-col pt-16 md:pt-20 px-4 bg-gray-50 min-h-screen">
            <div className="flex flex-row justify-center my-3">
              {order.status != "cancel" && (
                <div className="w-full md:w-1/2">
                  <button
                    className="bg-gray-500 text-white rounded p-2 px-6 text-sm"
                    onClick={() => setIsOpenModalStatus(true)}>
                    Ubah Status
                  </button>
                </div>
              )}
            </div>
            <div className="my-2 justify-center flex">
              <div className="flex w-full lg:w-1/2 md:w-1/2 flex-row justify-center  bg-white">
                <div className="container mx-auto px-4 border rounded shadow pb-10 ">
                  <div className="mt-2">
                    <h2 className={"font-bold text-pink-500"}>Info Pesanan</h2>
                    <div className="mt-2  md:flex md:flex-row md:justify-between">
                      <p className="font-bold">Tanggal Pesanan</p>
                      <p>{formatDate(order.date_created)}</p>
                    </div>
                    <div className="mt-2 md:flex md:flex-row md:justify-between">
                      <p className="font-bold">Status Pesanan</p>
                      <p
                        className={
                          order.status == "pending"
                            ? "text-yellow-500"
                            : order.status == "process"
                            ? "text-blue-500"
                            : order.status == "shipping"
                            ? "text-green-500"
                            : order.status == "cancel"
                            ? "text-red-500"
                            : ""
                        }>
                        {defineStatus[order.status]}
                      </p>
                    </div>
                    <div className="mt-2  md:flex md:flex-row md:justify-between">
                      <p className="font-bold">Total Pesanan</p>
                      <p>{formatCurrency(order.total)}</p>
                    </div>
                    {/* <div className="mt-2   md:flex md:flex-row md:justify-between">
                      <p className="font-bold">Alamat Pengiriman</p>
                      <p>{order.shipping_address}</p>
                    </div> */}
                    <div className="mt-2 md:flex md:flex-row md:justify-between">
                      <p className="font-bold">Penerima</p>
                      <p>{order.shipping_name}</p>
                    </div>
                    <div className="mt-2 md:flex md:flex-row md:justify-between">
                      <p className="font-bold">Nomor Telepon</p>
                      <p>{order.shipping_phone}</p>
                    </div>
                    <div className="mt-2  md:flex md:flex-row md:justify-between">
                      <p className="font-bold">Pengirim</p>
                      <p>{order.shipper_name}</p>
                    </div>
                    <div className="mt-2  md:flex md:flex-row md:justify-between">
                      <p className="font-bold">Catatan</p>
                      <p>{order.note}</p>
                    </div>
                    <div className="mt-10">
                      <h2 className={"font-bold  text-pink-500"}>
                        Detail Pesanan
                      </h2>
                      <div className="mt-2">
                        {order?.items?.length > 0 &&
                          order.items.map((item, index) => (
                            <div
                              key={index}
                              className="flex justify-between items-end border-b-2 py-2">
                              <div>
                                <p className="font-bold">
                                  {item.catalog_id.name}
                                </p>
                                {item?.catalog_variant_id && (
                                  <p className="text-xs">
                                    {item?.catalog_variant_id.name}
                                  </p>
                                )}
                                <p>
                                  {item.qty} x{" "}
                                  {formatCurrency(item.final_price)}
                                </p>
                                {item?.final_price < item?.price && (
                                  <p className="text-xs line-through text-gray-500">
                                    {formatCurrency(item.price)}
                                  </p>
                                )}
                              </div>
                              <div>
                                <p>{formatCurrency(item.subtotal)}</p>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="mt-2 md:flex md:flex-row md:justify-between">
                        <p className="font-medium">Subtotal</p>
                        <p className="">{formatCurrency(order.subtotal)}</p>
                      </div>
                      <div className="mt-2 md:flex md:flex-row md:justify-between">
                        <p className="font-medium">Tambahan diskon</p>
                        <p className="">
                          -{formatCurrency(order.additional_discount)}
                        </p>
                      </div>
                    </div>

                    <div className="mt-5">
                      <h2 className={"font-bold  text-pink-500"}>
                        Detail Pengiriman
                      </h2>
                      <div className="mt-2">
                        <p className="font-bold">Tujuan Pengiriman</p>
                        <p className="text-sm">{order.shipping_address}</p>
                        <p className="text-sm">
                          {order.shipping_province}, {order.shipping_city},{" "}
                          {order.shipping_subdistrict}
                        </p>
                      </div>
                      <div className="mt-2 md:flex md:flex-row md:justify-between">
                        <p className="font-medium">Layanan pengiriman</p>
                        <p>
                          {order.shipping_provider} -{" "}
                          {order.shipping_provider_service}
                        </p>
                      </div>
                      <div className=" md:flex md:flex-row md:justify-between">
                        <p className="font-medium">Biaya pengiriman</p>
                        <p>{formatCurrency(order.shipping_cost)}</p>
                      </div>
                    </div>
                    <div className="mt-5">
                      <h2 className={"font-bold  text-pink-500"}>
                        Total Pembayaran
                      </h2>

                      <div className="mt-2 md:flex md:flex-row md:justify-between">
                        <p className="font-medium">Tambahan biaya</p>
                        <p>{formatCurrency(order.additional_cost)}</p>
                      </div>
                      <div className="md:flex md:flex-row md:justify-between">
                        <p className="font-bold">Total</p>
                        <p className="font-bold">
                          {formatCurrency(order.total)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpenModalStatus && (
        <div className="fixed inset-0 flex items-center justify-center z-30">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-8 z-50 mx-4 w-96">
            <p>Ubah Status Pesanan menjadi :</p>

            <div className="flex mt-2 flex-col justify-between">
              <button
                onClick={() => {
                  changeStatus("process");
                  setIsOpenModalStatus(false);
                }}
                className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 text-left rounded border-b">
                Dalam Proses
              </button>
              <button
                onClick={() => {
                  changeStatus("delivery");
                  setIsOpenModalStatus(false);
                }}
                className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 text-left rounded border-b">
                Dalam Pengiriman
              </button>
              <button
                onClick={() => {
                  changeStatus("completed");
                  setIsOpenModalStatus(false);
                }}
                className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 text-left rounded border-b">
                Selesai
              </button>
              <button
                onClick={() => {
                  changeStatus("cancel");
                  setIsOpenModalStatus(false);
                }}
                className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 text-left rounded">
                Dibatalkan
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default AdminquOrderDetail;
