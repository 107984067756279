import React, { useState, useEffect, useRef } from "react";
import {
  FaChevronLeft,
  FaWhatsapp,
  FaWindowClose,
  FaCloudDownloadAlt,
  FaLink,
  FaShoppingCart,
  FaCheck,
  FaCheckCircle,
} from "react-icons/fa";
import { useParams, useNavigate, Link } from "react-router-dom";

import { InlineShareButtons } from "sharethis-reactjs";

import Api from "../../services/Api";
import Config from "../../config/Config";

import ReactLoading from "react-loading";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { ToastContainer, toast } from "react-toastify";
import CartFloat from "../../components/CartFloat";

function Detail() {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [catalog, setCatalog] = useState({});
  const [store, setStore] = useState({});
  const [additionalImages, setAdditionalImages] = useState([]);

  const [selectedFilePreview, setSelectedFilePreview] = useState();

  const [isOpen, setIsOpen] = useState(false);

  const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);

  const [isModalOrderOpen, setIsModalOrderOpen] = useState(false);

  const [qty, setQty] = useState(1);

  const [variantSelected, setVariantSelected] = useState({});

  const [refreshCart, setRefreshCart] = useState(false);

  const [user, setUser] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const checkIsLogin = () => {
    const token = localStorage.getItem("customer_token");

    Api.get("/users/me?fields=*.*", token)
      .then((res) => {
        console.log("res user group", res);

        if (res?.data) {
          console.log("data", res.data);
          setUser(res.data);
        } else {
          setUser(null);
        }
      })
      .catch((error) => {
        setUser(null);
      });
  };

  const addToCart = async () => {
    const token = localStorage.getItem("customer_token");

    const data = {
      catalog_id: catalog.id,
      catalog_variant_id: variantSelected?.id,
      qty: qty,
    };

    Api.post("/flows/trigger/48b8cbaa-a5dd-4393-9660-17327983a3d8", data, token)
      .then((response) => {
        //console.log(response);
        if (response?.status == "Success") {
          setIsModalOrderOpen(false);

          toast.success("Berhasil menambahkan ke keranjang", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });

          setRefreshCart(new Date());
        } else {
          if (response?.status == 403) {
            window.location.href = "/login";
            return;
          }
          toast.error(
            "Gagal menambahkan ke keranjang " +
              (response?.message && "(" + response?.message) +
              ")",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            }
          );
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    const getStore = async (id) => {
      Api.get("/items/Store/" + id + "?fields=*")
        .then((response) => {
          //console.log(response);
          if (response) {
            setStore(response.data);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getCatalog = async () => {
      Api.get(
        "/items/Catalog/" +
          id +
          "?fields=*, images.*, links.*, variations.*, price_customer_group.*"
      )
        .then((response) => {
          //console.log(response);
          if (response) {
            // sort A-Z variations by name
            response.data.variations.sort((a, b) => (a.name > b.name ? 1 : -1));
            setCatalog(response.data);
            setVariantSelected(response.data.variations[0]);
            getStore(response.data.store_id);

            const additionalImages = response.data.images.filter(
              (item) => item.image != response.data.image
            );

            // sort by sequence
            additionalImages.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

            setAdditionalImages(additionalImages);
            setIsInitialLoading(false);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    setIsInitialLoading(true);
    getCatalog();
    checkIsLogin();
  }, []);

  const formatCurrency = (number) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });

    return formatter.format(number);
  };

  const dateFormat = (date) => {
    if (date != null && date != undefined) {
      const parts = date.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        return `${day}/${month}/${year}`;
      }
      return date;
    } else {
      return "-";
    }
  };

  const downloadImage = (image) => {
    window.open(Config.api_url + "/assets/" + image + "?download", "_self");
  };

  return isInitialLoading ? (
    <div className="px-4 sm:pt-24 pt-36 pb-4 flex justify-center">
      <ReactLoading type={"balls"} color={"gray"} height={10} width={48} />
    </div>
  ) : (
    <div>
      <div className="fixed w-full ml-0 mt-0 px-4 py-4 shadow bg-white z-50">
        <div className="flex flex-row justify-between items-center">
          <div className="w-1/5">
            <FaChevronLeft
              className={"text-" + store.color + "-500 cursor-pointer"}
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="flex-1">
            <div className="text-lg font-bold text-center ">{catalog.name}</div>
          </div>
          <div className="w-1/5 text-right">
            <div className="hidden sm:inline-block">
              <InlineShareButtons
                config={{
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  font_size: 12, // font size for the buttons
                  labels: "null", // button labels (cta, counts, null)
                  language: "en", // which language to use (see LANGUAGES)
                  networks: [
                    // which networks to include (see SHARING NETWORKS)
                    "whatsapp",
                    "messenger",
                    "instagram",
                    "facebook",
                    "twitter",
                  ],
                  padding: 8, // padding within buttons (INTEGER)
                  radius: 4, // the corner radius on each button (INTEGER)
                  size: 32, // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS
                  // (defaults to current url)
                  image: Config.api_url + "/assets/" + catalog.image, // (defaults to og:image or twitter:image)
                  description: catalog.description, // (defaults to og:description or twitter:description)
                  title: catalog.name, // (defaults to og:title or twitter:title)
                  message: catalog.description, // (only for email sharing)
                  subject: catalog.name, // (only for email sharing)
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 pt-20 sm:pt-24 pb-12">
        <div className="flex flex-col sm:flex-row gap-4 justify-center w-full">
          <div className="rounded w-full sm:w-1/3">
            <div className="sm:hidden mb-4 z">
              <InlineShareButtons
                config={{
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  font_size: 12, // font size for the buttons
                  labels: "null", // button labels (cta, counts, null)
                  language: "en", // which language to use (see LANGUAGES)
                  networks: [
                    // which networks to include (see SHARING NETWORKS)
                    "whatsapp",
                    "messenger",
                    "instagram",
                    "facebook",
                    "twitter",
                  ],
                  padding: 8, // padding within buttons (INTEGER)
                  radius: 4, // the corner radius on each button (INTEGER)
                  size: 32, // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS
                  // (defaults to current url)
                  image: Config.api_url + "/assets/" + catalog.image, // (defaults to og:image or twitter:image)
                  description: catalog.description, // (defaults to og:description or twitter:description)
                  title: catalog.name, // (defaults to og:title or twitter:title)
                  message: catalog.description, // (only for email sharing)
                  subject: catalog.name, // (only for email sharing)
                }}
              />
            </div>

            <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
              <div className="w-full flex flex-nowrap">
                <div className="inline-block px-2 cursor-pointer">
                  <div className="w-64 h-72 md:w-80 md:h-88 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out text-right">
                    <div className="w-64 h-72 md:w-80 md:h-88 overflow-hidden">
                      <img
                        src={Config.api_url + "/assets/" + catalog.image}
                        alt="Product 1"
                        className="w-full h-auto"
                        onClick={() => {
                          setSelectedFilePreview(catalog.image);
                          setIsOpen(true);
                        }}
                      />
                    </div>
                    <div className="-mt-12 z-10">
                      <button
                        className={
                          "bg-" +
                          store.color +
                          "-500 hover:bg-" +
                          store.color +
                          "-700 text-white font-semibold py-2 px-1 rounded-full z-10 mr-2"
                        }
                        onClick={() => {
                          downloadImage(catalog.image);
                        }}>
                        <FaCloudDownloadAlt className="inline-block w-8" />
                      </button>
                    </div>
                  </div>
                </div>
                {catalog.images &&
                  additionalImages.map((item) => (
                    <div className="inline-block px-2 cursor-pointer">
                      <div className="w-64 h-72 md:w-80 md:h-88 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out text-right">
                        <div className="w-64 h-72 md:w-80 md:h-88 overflow-hidden">
                          <img
                            src={Config.api_url + "/assets/" + item.image}
                            alt="Product 1"
                            className="w-full h-auto"
                            onClick={() => {
                              setSelectedFilePreview(item.image);
                              setIsOpen(true);
                            }}
                          />
                        </div>
                        <div className="-mt-12 z-10">
                          <button
                            className={
                              "bg-" +
                              store.color +
                              "-500 hover:bg-" +
                              store.color +
                              "-700 text-white font-semibold py-2 px-1 rounded-full z-10 mr-2"
                            }
                            onClick={() => {
                              downloadImage(item.image);
                            }}>
                            <FaCloudDownloadAlt className="inline-block w-8" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="px-4 mb-2 w-full sm:w-1/3">
            <div className="flex flex-row justify-between items-center">
              <div className="flex-1">
                <div
                  className={"text-xl font-bold text-" + store.color + "-500"}>
                  {catalog.variations?.length > 0 ? (
                    <>
                      {variantSelected.price_discount > 0 ? (
                        <>
                          <span className="line-through pr-1">
                            {formatCurrency(variantSelected.price)}
                          </span>
                          <span className="text-green-500">
                            {formatCurrency(variantSelected.price_discount)}
                          </span>
                        </>
                      ) : (
                        <span>{formatCurrency(variantSelected.price)}</span>
                      )}
                    </>
                  ) : catalog.is_discount == 1 ? (
                    <>
                      {catalog.is_range_price ? (
                        <p className={"text-red-500 font-normal"}>
                          <span className=" line-through pr-1">
                            {formatCurrency(catalog.price_from)} -{" "}
                            {formatCurrency(catalog.price_to)}
                          </span>
                          <br />
                          <span
                            className={
                              "text-" + store.color + "-500 font-semibold"
                            }>
                            {formatCurrency(catalog.discount_from)} -{" "}
                            {formatCurrency(catalog.discount_to)}
                          </span>
                        </p>
                      ) : (
                        <p className={"text-red-500 font-normal"}>
                          <span className=" line-through pr-1">
                            {formatCurrency(catalog.price)}
                          </span>
                          <span
                            className={
                              "text-" + store.color + "-500 font-semibold"
                            }>
                            {formatCurrency(catalog.discount)}
                          </span>
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {catalog.is_range_price ? (
                        <p
                          className={
                            "text-" + store.color + "-500 font-semibold"
                          }>
                          {formatCurrency(catalog.price_from)} -{" "}
                          {formatCurrency(catalog.price_to)}
                        </p>
                      ) : (
                        <p
                          className={
                            "text-" + store.color + "-500 font-semibold"
                          }>
                          {formatCurrency(catalog.price)}
                        </p>
                      )}
                    </>
                  )}
                </div>

                {/* check if login customer */}
                {user?.store_customer_group_id?.id && (
                  <div className="text-xs text-gray-500">
                    Harga untuk {user?.store_customer_group_id?.name}
                    {catalog.price_customer_group &&
                      catalog.price_customer_group.map((item) => {
                        if (
                          item.customer_group_id ==
                          user.store_customer_group_id.id
                        ) {
                          return (
                            <div>
                              {catalog.is_discount ? (
                                <>
                                  <span className="text-green-500">
                                    {formatCurrency(
                                      parseInt(catalog.discount) -
                                        (parseInt(catalog.discount) *
                                          parseInt(item.discount)) /
                                          100
                                    )}
                                  </span>
                                </>
                              ) : (
                                <span className="text-green-500">
                                  {formatCurrency(
                                    parseInt(catalog.discount) -
                                      (parseInt(catalog.price) *
                                        parseInt(item.discount)) /
                                        100
                                  )}
                                </span>
                              )}
                            </div>
                          );
                        }
                      })}
                  </div>
                )}

                <div className="w-full">
                  {catalog.is_preorder && (
                    <div>
                      <p className="text-gray-500 text-xs mt-2 text-left ">
                        {catalog.preorder_additional_text}
                      </p>
                      <p className="text-gray-500 text-xs mt-2 text-left ">
                        <span
                          className={
                            "bg-" +
                            store.color +
                            "-500 text-white px-2 py-1 rounded "
                          }>
                          {dateFormat(catalog.preorder_closed_date)}
                        </span>
                      </p>
                    </div>
                  )}
                </div>

                {catalog.variations?.length > 0 && (
                  <div className="w-full py-2 pb-4 mt-4 border-b border-t">
                    <div className="my-2 font-bold text-sm">Variasi</div>
                    <div className="flex flex-row flex-wrap ">
                      {catalog.variations.map((item, index) => (
                        <div className="pr-2 pb-2 w-1/2">
                          <div
                            onClick={() => {
                              setVariantSelected(item);
                            }}
                            key={index}
                            className={
                              variantSelected.id == item.id
                                ? "flex flex-row gap-2 bg-green-100 p-2 pr-4 rounded cursor-pointer"
                                : "flex flex-row gap-2 bg-gray-100 p-2 pr-4 rounded cursor-pointer"
                            }>
                            <div className="w-[15px]">
                              {variantSelected.id == item.id && (
                                <>
                                  <FaCheckCircle className="text-green-500" />
                                </>
                              )}
                            </div>
                            <div>
                              <div className="text-xs md:text-sm">
                                {item.name}
                              </div>
                              <div className="text-xs md:text-sm flex-1">
                                {item.price_discount > 0 ? (
                                  <>
                                    <span className="text-red-500 text-xs line-through pr-1">
                                      {formatCurrency(item.price)}
                                    </span>
                                    <span className=" text-xs text-green-500">
                                      {formatCurrency(item.price_discount)}
                                    </span>
                                  </>
                                ) : (
                                  <span class="text-xs">
                                    {formatCurrency(item.price)}
                                  </span>
                                )}
                              </div>
                              <div className="text-xs text-gray-500">
                                Stok: {item.stock}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="py-4 pb-16 sm:pb-8 text-sm">
              <div className="my-2 font-bold text-sm">Deskripsi</div>
              {ReactHtmlParser(catalog.description)}
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          "fixed flex flex-row justify-between bottom-0 w-full bg-" +
          store.color +
          "-500 text-center text-white text-lg"
        }>
        {store.order_method == "whatsapp" && (
          <button
            className={
              catalog.links && catalog.links.length > 0
                ? "text-sm w-1/2 p-4 items-center"
                : "text-sm w-full p-4 items-center"
            }
            onClick={() => {
              const waNumber = store.whatsapp_number;
              // replace 0 with 62
              const waNumberWithoutZero = waNumber.replace(/^0+/, "62");
              // replace space with nothing
              const waNumberWithoutSpace = waNumberWithoutZero.replace(
                /\s/g,
                ""
              );

              var text = "";

              if (catalog.is_range_price) {
                text = `Halo ${store.name},%0a %0aSaya ingin order *${
                  catalog.name
                } dengan harga ${
                  catalog.is_discount
                    ? formatCurrency(catalog.discount_from) +
                      " - " +
                      formatCurrency(catalog.discount_to)
                    : formatCurrency(catalog.price_from) +
                      " - " +
                      formatCurrency(catalog.price_to)
                }* di Kataloqu.com%0a %0a*Link katalog*: ${
                  window.location.href
                }  `;
              } else {
                text = `Halo ${store.name},%0a %0aSaya ingin order *${
                  catalog.name
                } dengan harga ${formatCurrency(
                  catalog.is_discount ? catalog.discount : catalog.price
                )}* di Kataloqu.com%0a %0a*Link katalog*: ${
                  window.location.href
                }  `;
              }

              window.open(
                `https://wa.me/${waNumberWithoutSpace}?text=${text}`,
                "_blank"
              );
            }}>
            <FaWhatsapp className="inline-block mr-2" />
            Order via Whatsapp
          </button>
        )}

        {catalog.accept_online_order && (
          <button
            className={
              catalog.links && catalog.links.length > 0
                ? "bg-" + store.color + "-700 text-sm w-1/2 p-4 items-center"
                : "bg-" + store.color + "-700 text-sm w-full p-4 items-center"
            }
            onClick={() => {
              setIsModalOrderOpen(true);
            }}>
            <FaShoppingCart className="inline-block mr-2" />
            Tambahkan
          </button>
        )}

        {catalog.links && catalog.links.length > 0 && (
          <button
            className={
              "w-1/2 bg-" +
              store.color +
              "-700 font-bold text-sm text-center items-center"
            }
            onClick={() => {
              setIsModalLinkOpen(true);
            }}>
            <FaLink className="inline-block mr-2" />
            Link
          </button>
        )}
      </div>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-2 z-50 mx-4 h-auto">
            <div className="flex flex-row justify-between">
              <p>Gambar {catalog.name}</p>
              <button
                className=" mb-4 p-0 text-xl"
                onClick={() => setIsOpen(false)}>
                <FaWindowClose className="text-red-500 bg-white" />
              </button>
            </div>
            <div className="h-auto  overflow-y-auto">
              <img
                src={Config.api_url + "/assets/" + selectedFilePreview}
                alt="Product 1"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      )}

      {isModalLinkOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-2 w-full z-50 mx-4 h-auto">
            <div className="flex flex-row justify-between">
              <p>Link {catalog.name}</p>
              <button
                className=" mb-4 p-0 text-xl"
                onClick={() => setIsModalLinkOpen(false)}>
                <FaWindowClose className="text-red-500 bg-white" />
              </button>
            </div>
            <div className="h-auto px-4 flex flex-col overflow-y-auto ">
              {/* loop link as button */}
              {catalog.links &&
                catalog.links.map((item) => (
                  <button
                    className="p-4 rounded-lg bg-gray-100 flex flex-row justify-center items-center gap-2 mb-4 p-0 text-xl"
                    onClick={() => {
                      window.open(item.url, "_blank");
                      setIsModalLinkOpen(false);
                    }}>
                    <FaLink className="text-red-500 " />
                    <span className="text-sm">{item.label}</span>
                  </button>
                ))}
            </div>
          </div>
        </div>
      )}

      {isModalOrderOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-2 max-w-96 z-50 mx-4 h-auto">
            <div className="flex flex-row justify-between border-b pt-2">
              <p className="pl-4 text-sm font-bold">Order</p>
              <button
                className=" mb-4 p-0 text-xl"
                onClick={() => setIsModalOrderOpen(false)}>
                <FaWindowClose className="text-red-500 bg-white" />
              </button>
            </div>

            <div className="h-auto p-4 flex flex-col overflow-y-auto ">
              <div>
                {/* create qty input with + - button */}
                <div className="mb-4">{catalog.name}</div>
                {catalog.variations?.length > 0 && (
                  <div className="mb-4 text-sm flex flex-row justify-between">
                    <div>Varian : </div>
                    <div>{variantSelected.name}</div>
                  </div>
                )}

                <div className="flex flex-row justify-between items-center">
                  <div className="text-sm">Jumlah</div>
                  <div className="flex flex-row justify-between items-center">
                    <button
                      className="bg-gray-100 text-gray-500 w-8 h-8 rounded-full"
                      onClick={() => {
                        if (qty > 1) setQty(qty - 1);
                      }}>
                      -
                    </button>
                    <input
                      type="number"
                      className="w-16 text-center"
                      value={qty}
                      onChange={(e) => {
                        setQty(e.target.value);
                      }}
                    />
                    <button
                      className="bg-gray-100 text-gray-500 w-8 h-8 rounded-full"
                      onClick={() => {
                        setQty(qty + 1);
                      }}>
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4">
              <button
                onClick={() => {
                  addToCart();
                }}
                className={
                  "bg-" + store.color + "-500 text-white rounded-lg p-4 w-full"
                }>
                Tambah ke keranjang
              </button>
            </div>
          </div>
        </div>
      )}

      <CartFloat store={store} refresh={refreshCart} />

      <ToastContainer />
    </div>
  );
}

export default Detail;
