import React, { useState, useEffect } from "react";

import Header from "../../components/Header";
import Api from "../../services/Api";
import Config from "../../config/Config";

import { FaTrash, FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";

var httpHost = window.location.hostname;
var domain = "https://kataloqu.com";

if (httpHost == "localhost" || httpHost == "192.168.1.7") {
  httpHost = "mycollection.kataloqu.com";
}

function Cart() {
  const [store, setStore] = useState({});
  const [cart, setCart] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const formatCurrency = (number) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });

    return formatter.format(number);
  };

  const deleteCart = async (id) => {
    const token = localStorage.getItem("customer_token");
    Api.delete("/items/Cart/" + id, null, token)
      .then((response) => {
        console.log(response);

        toast.success("Berhasil menghapus item dari keranjang");
        getCart();
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getCart = async () => {
    const token = localStorage.getItem("customer_token");
    Api.get("/items/Cart?fields=*.*.*", token)
      .then((response) => {
        console.log(response);
        if (response) {
          setCart(response.data);
          let total = 0;
          let amount = 0;
          response.data.map((item) => {
            total += item.qty;
            amount += item.subtotal;
          });
          setTotalQty(total);
          setTotalAmount(amount);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    const subdomain = httpHost.split(".")[0];

    const getStore = async () => {
      Api.get(
        "/items/Store?filter[subdomain][_eq]=" +
          subdomain +
          "&filter[is_activated][_eq]=1&fields=*"
      )
        .then((response) => {
          //console.log(response);
          if (response.data.length == 0) {
            // 404 page
            window.location.href = "/expired";
          }

          if (response) {
            setStore(response.data[0]);
            document.title = response.data[0].name + " - by Kataloqu";
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getCart = async () => {
      const token = localStorage.getItem("customer_token");
      Api.get("/items/Cart?fields=*.*.*", token)
        .then((response) => {
          console.log(response);
          if (response) {
            setCart(response.data);
            let total = 0;
            let amount = 0;
            response.data.map((item) => {
              total += item.qty;
              amount += item.subtotal;
            });
            setTotalQty(total);
            setTotalAmount(amount);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    getStore();
    getCart();
  }, []);

  return (
    <div className="flex-1 flex justify-center ">
      <Header />

      <div className="px-4 sm:pt-28 pt-56 pb-4 md:w-1/2">
        {/* Cart list view */}
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-lg font-semibold">Keranjang Belanja</h1>
          </div>
          <div className="flex flex-col gap-4">
            {cart.map((item, index) => (
              <div
                className="flex flex-row items-center gap-4 border-b pb-4"
                key={index}>
                <div className="w-16 h-16 bg-gray-200 rounded-full">
                  {item.catalog_id.image != null && (
                    <img
                      src={
                        Config.api_url + "/assets/" + item.catalog_id.image.id
                      }
                      alt={item.catalog_id.name}
                      className="w-full h-full rounded-full"
                    />
                  )}
                </div>
                <div className="flex flex-col flex-1">
                  <div className="mb-2">
                    <h2 className="text-sm font-semibold">
                      {item.catalog_id.name}
                    </h2>
                    {item?.catalog_variant_id != null && (
                      <div className="text-xs">
                        Varian : {item.catalog_variant_id.name}
                      </div>
                    )}
                  </div>
                  <div>
                    {item.final_price < item.price && (
                      <span className="text-xs line-through mr-1 text-red-500">
                        {formatCurrency(item.price)}
                      </span>
                    )}
                    <span className="text-xs">
                      {formatCurrency(item.final_price)}
                    </span>
                  </div>
                  <span className="text-xs">Qty: {item.qty}</span>
                  <span className="text-sm font-medium">
                    Subtotal: {formatCurrency(item.subtotal)}
                  </span>
                </div>
                <div>
                  <button
                    onClick={() => {
                      setIsModalDeleteOpen(true);
                      setDeleteId(item.id);
                    }}
                    className={
                      "bg-red-500 hover:bg-red-700 text-white text-sm font-bold p-2 rounded-lg"
                    }>
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Cart summary */}
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-lg font-semibold">Ringkasan Belanja</h1>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-center justify-between">
                <span className="text-sm">Total Item</span>
                <span className="text-sm">{totalQty}</span>
              </div>
              <div className="flex flex-row items-center justify-between">
                <span className="text-md">Jumlah belanja</span>
                <span className="text-md font-bold">
                  {formatCurrency(totalAmount)}
                </span>
              </div>
            </div>
          </div>

          {/* Checkout */}
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center justify-between">
              <Link
                to="/"
                className={
                  "mt-4 bg-gray-200 text-gray-900 text-sm font-bold py-2 px-4 rounded-lg"
                }>
                Kembali belanja
              </Link>
              <Link
                to="/checkout"
                className={
                  "mt-4 bg-" +
                  store.color +
                  "-500 hover:bg-" +
                  store.color +
                  "-700 text-white text-sm font-bold py-2 px-4 rounded-lg"
                }>
                Checkout
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Modal delete */}
      <div
        className={
          (isModalDeleteOpen ? "fixed" : "hidden") +
          " inset-0 w-full h-full z-50 overflow-hidden flex justify-center items-center bg-black bg-opacity-50"
        }>
        <div className="bg-white rounded-lg w-3/4 md:w-64 p-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-lg font-semibold">Hapus item</h1>
              <button
                onClick={() => setIsModalDeleteOpen(false)}
                className="text-gray-400 hover:text-gray-700">
                <FaTimes />
              </button>
            </div>
            <div className="flex flex-col gap-4">
              <span>Apakah anda yakin ingin menghapus item ini?</span>
              <div className="flex flex-row gap-2">
                <button
                  onClick={() => setIsModalDeleteOpen(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white text-sm font-bold py-2 px-4 rounded-lg">
                  Batal
                </button>
                <button
                  onClick={() => {
                    setIsModalDeleteOpen(false);
                    deleteCart(deleteId);
                  }}
                  className={
                    "bg-red-500 hover:bg-red-700 text-white text-sm font-bold py-2 px-4 rounded-lg"
                  }>
                  Hapus
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Cart;
