import React, { useState, useEffect, useRef } from "react";
import {
  FaChevronLeft,
  FaWhatsapp,
  FaWindowClose,
  FaCloudDownloadAlt,
} from "react-icons/fa";
import { useParams, useNavigate, Link } from "react-router-dom";

import { InlineShareButtons } from "sharethis-reactjs";

import Api from "../../services/Api";
import Config from "../../config/Config";

import ReactLoading from "react-loading";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const currentUrl = window.location.href;
const segment2 = currentUrl.split("/")[3];
const segment3 = currentUrl.split("/")[4];

function ResellerDetail() {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [catalog, setCatalog] = useState({});
  const [store, setStore] = useState({});
  const [reseller, setReseller] = useState({});
  const [additionalImages, setAdditionalImages] = useState([]);

  const [selectedFilePreview, setSelectedFilePreview] = useState();

  const [isOpen, setIsOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getStore = async (id) => {
      Api.get("/items/Store/" + id + "?fields=*")
        .then((response) => {
          //console.log(response);
          if (response) {
            setStore(response.data);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getReseller = async () => {
      Api.get(
        "/items/Link?filter[slug][_eq]=" +
          segment2 +
          "/" +
          segment3 +
          "&fields=*"
      )
        .then((response) => {
          //console.log(response);
          if (response) {
            setReseller(response.data[0]);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getCatalog = async () => {
      Api.get("/items/Catalog/" + id + "?fields=*, images.*, variations.*")
        .then((response) => {
          //console.log(response);
          if (response) {
            setCatalog(response.data);
            getStore(response.data.store_id);
            getReseller();

            setIsInitialLoading(false);

            const additionalImages = response.data.images.filter(
              (item) => item.image != response.data.image
            );
            // sort by sequence
            additionalImages.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

            setAdditionalImages(additionalImages);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    setIsInitialLoading(true);
    getCatalog();
  }, []);

  const formatCurrency = (number) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });

    return formatter.format(number);
  };

  const dateFormat = (date) => {
    if (date != null && date != undefined) {
      const parts = date.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        return `${day}/${month}/${year}`;
      }
      return date;
    } else {
      return "-";
    }
  };

  const downloadImage = (image) => {
    window.open(Config.api_url + "/assets/" + image + "?download", "_self");
  };

  return isInitialLoading ? (
    <div className="px-4 sm:pt-24 pt-36 pb-4 flex justify-center">
      <ReactLoading type={"balls"} color={"gray"} height={10} width={48} />
    </div>
  ) : (
    <div>
      <div className="fixed w-full ml-0 mt-0 px-4 py-4 shadow bg-white z-50">
        <div className="flex flex-row justify-between items-center">
          <div className="w-1/5">
            <FaChevronLeft
              className={"text-" + store.color + "-500 cursor-pointer"}
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="flex-1">
            <div className="text-lg font-bold text-center ">{catalog.name}</div>
          </div>
          <div className="w-1/5 text-right">
            <div className="hidden sm:inline-block">
              <InlineShareButtons
                config={{
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  font_size: 12, // font size for the buttons
                  labels: "null", // button labels (cta, counts, null)
                  language: "en", // which language to use (see LANGUAGES)
                  networks: [
                    // which networks to include (see SHARING NETWORKS)
                    "whatsapp",
                    "messenger",
                    "instagram",
                    "facebook",
                    "twitter",
                  ],
                  padding: 8, // padding within buttons (INTEGER)
                  radius: 4, // the corner radius on each button (INTEGER)
                  size: 32, // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS
                  // (defaults to current url)
                  image: Config.api_url + "/assets/" + catalog.image, // (defaults to og:image or twitter:image)
                  description: catalog.description, // (defaults to og:description or twitter:description)
                  title: catalog.name, // (defaults to og:title or twitter:title)
                  message: catalog.description, // (only for email sharing)
                  subject: catalog.name, // (only for email sharing)
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 pt-20 sm:pt-24 pb-12">
        <div className="flex flex-col sm:flex-row gap-4 justify-center w-full">
          <div className="rounded w-full sm:w-1/3">
            <div className="sm:hidden mb-4 z">
              <InlineShareButtons
                config={{
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  font_size: 12, // font size for the buttons
                  labels: "null", // button labels (cta, counts, null)
                  language: "en", // which language to use (see LANGUAGES)
                  networks: [
                    // which networks to include (see SHARING NETWORKS)
                    "whatsapp",
                    "messenger",
                    "instagram",
                    "facebook",
                    "twitter",
                  ],
                  padding: 8, // padding within buttons (INTEGER)
                  radius: 4, // the corner radius on each button (INTEGER)
                  size: 32, // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS
                  // (defaults to current url)
                  image: Config.api_url + "/assets/" + catalog.image, // (defaults to og:image or twitter:image)
                  description: catalog.description, // (defaults to og:description or twitter:description)
                  title: catalog.name, // (defaults to og:title or twitter:title)
                  message: catalog.description, // (only for email sharing)
                  subject: catalog.name, // (only for email sharing)
                }}
              />
            </div>

            <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
              <div className="w-full flex flex-nowrap">
                <div className="inline-block px-2 cursor-pointer">
                  <div className="w-64 h-72 md:w-80 md:h-88 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out text-right">
                    <div className="w-64 h-72 md:w-80 md:h-88 overflow-hidden">
                      <img
                        src={Config.api_url + "/assets/" + catalog.image}
                        alt="Product 1"
                        className="w-full h-auto"
                        onClick={() => {
                          setSelectedFilePreview(catalog.image);
                          setIsOpen(true);
                        }}
                      />
                    </div>
                    <div className="-mt-12 z-10">
                      <button
                        className={
                          "bg-" +
                          store.color +
                          "-500 hover:bg-" +
                          store.color +
                          "-700 text-white font-semibold py-2 px-1 rounded-full z-10 mr-2"
                        }
                        onClick={() => {
                          downloadImage(catalog.image);
                        }}>
                        <FaCloudDownloadAlt className="inline-block w-8" />
                      </button>
                    </div>
                  </div>
                </div>
                {catalog.images &&
                  additionalImages.map((item) => (
                    <div className="inline-block px-2 cursor-pointer">
                      <div className="w-64 h-72 md:w-80 md:h-88 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out text-right">
                        <div className="w-64 h-72 md:w-80 md:h-88 overflow-hidden">
                          <img
                            src={Config.api_url + "/assets/" + item.image}
                            alt="Product 1"
                            className="w-full h-auto"
                            onClick={() => {
                              setSelectedFilePreview(item.image);
                              setIsOpen(true);
                            }}
                          />
                        </div>
                        <div className="-mt-12 z-10">
                          <button
                            className={
                              "bg-" +
                              store.color +
                              "-500 hover:bg-" +
                              store.color +
                              "-700 text-white font-semibold py-2 px-1 rounded-full z-10 mr-2"
                            }
                            onClick={() => {
                              downloadImage(item.image);
                            }}>
                            <FaCloudDownloadAlt className="inline-block w-8" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="px-4 mb-2 w-full sm:w-1/3">
            <div className="flex flex-col justify-between ">
              <div>
                <div
                  className={"text-xl font-bold text-" + store.color + "-500"}>
                  {catalog.is_discount && catalog.show_discount_in_reseller ? (
                    <>
                      {catalog.is_range_price ? (
                        <p className={"text-red-500 font-normal"}>
                          <span className=" line-through pr-1">
                            {formatCurrency(catalog.price_from)} -{" "}
                            {formatCurrency(catalog.price_to)}
                          </span>
                          <br />
                          <span
                            className={
                              "text-" + store.color + "-500 font-semibold"
                            }>
                            {formatCurrency(catalog.discount_from)} -{" "}
                            {formatCurrency(catalog.discount_to)}
                          </span>
                        </p>
                      ) : (
                        <p className={"text-red-500 font-normal"}>
                          <span className=" line-through pr-1">
                            {formatCurrency(catalog.price)}
                          </span>
                          <span
                            className={
                              "text-" + store.color + "-500 font-semibold"
                            }>
                            {formatCurrency(catalog.discount)}
                          </span>
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {catalog.is_range_price ? (
                        <p
                          className={
                            "text-" + store.color + "-500 font-semibold"
                          }>
                          {formatCurrency(catalog.price_from)} -{" "}
                          {formatCurrency(catalog.price_to)}
                        </p>
                      ) : (
                        <p
                          className={
                            "text-" + store.color + "-500 font-semibold"
                          }>
                          {formatCurrency(catalog.price)}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="w-full">
                {catalog.is_preorder && (
                  <div className="w-full">
                    <p className="text-gray-500 text-xs mt-2 text-left ">
                      {catalog.preorder_additional_text}
                    </p>
                    <p className="text-gray-500 text-xs mt-2 text-left ">
                      <span
                        className={
                          "bg-" +
                          store.color +
                          "-500 text-white px-2 py-1 rounded "
                        }>
                        {dateFormat(catalog.preorder_closed_date)}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {catalog.variations?.length > 0 && (
                <div className="w-full py-2 pb-4 mt-4 border-b border-t">
                  <div className="my-2 font-bold text-sm">Variasi</div>
                  <div className="flex flex-row flex-wrap ">
                    {catalog.variations.map((item, index) => (
                      <div className="pr-2 pb-2 w-1/2">
                        <div
                          key={index}
                          className={
                            "flex flex-row gap-2 bg-gray-100 p-2 pr-4 rounded cursor-pointer"
                          }>
                          <div className="w-[15px]"></div>
                          <div>
                            <div className="text-xs md:text-sm">
                              {item.name}
                            </div>
                            <div className="text-xs md:text-sm flex-1">
                              {item.price_discount > 0 ? (
                                <>
                                  <span className="text-red-500 text-xs line-through pr-1">
                                    {formatCurrency(item.price)}
                                  </span>
                                  <span className=" text-xs text-green-500">
                                    {formatCurrency(item.price_discount)}
                                  </span>
                                </>
                              ) : (
                                <span class="text-xs">
                                  {formatCurrency(item.price)}
                                </span>
                              )}
                            </div>
                            <div className="text-xs text-gray-500">
                              Stok: {item.stock}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="py-4 pb-16 sm:pb-8 text-sm">
              {ReactHtmlParser(catalog.description)}
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          "fixed bottom-0 w-full p-4 bg-" +
          store.color +
          "-500 text-center text-white text-lg"
        }>
        <button
          className=" items-center"
          onClick={() => {
            const waNumber = reseller.wa_number;
            // replace 0 with 62
            const waNumberWithoutZero = waNumber.replace(/^0+/, "62");
            // replace space with nothing
            const waNumberWithoutSpace = waNumberWithoutZero.replace(/\s/g, "");

            const text = `Halo ${reseller.name},%0a %0aSaya ingin order *${
              catalog.name
            }* dengan harga *${formatCurrency(
              catalog.is_discount ? catalog.discount : catalog.price
            )}* di Kataloqu.com%0a %0a *Link katalog*: ${
              window.location.href
            }  `;

            window.open(
              `https://wa.me/${waNumberWithoutSpace}?text=${text}`,
              "_blank"
            );
          }}>
          <FaWhatsapp className="inline-block mr-2" />
          Order via Whatsapp
        </button>
      </div>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-2 z-50 mx-4 h-auto">
            <div className="flex flex-row justify-between">
              <p>Gambar {catalog.name}</p>
              <button
                className=" mb-4 p-0 text-xl"
                onClick={() => setIsOpen(false)}>
                <FaWindowClose className="text-red-500 bg-white" />
              </button>
            </div>
            <div className="max-h-96 overflow-y-auto">
              <img
                src={Config.api_url + "/assets/" + selectedFilePreview}
                alt="Product 1"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResellerDetail;
