import React, { useState, useEffect } from "react";

import { useParams, Link } from "react-router-dom";
import { FaCopy, FaWindowClose, FaChevronRight } from "react-icons/fa";
import Api from "../../services/Api";
import Config from "../../config/Config";
import { ToastContainer, toast } from "react-toastify";

import ReactLoading from "react-loading";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import CartFloat from "../../components/CartFloat";

var httpHost = window.location.hostname;
var domain = "https://kataloqu.com";

if (httpHost == "localhost" || httpHost == "192.168.1.7") {
  httpHost = "mycollection.kataloqu.com";
}

function CatalogSale() {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [store, setStore] = useState({
    name: "",
    color: "",
  });

  const [catalogOriginal, setCatalogOriginal] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [search, setSearch] = useState("");

  const [resellerName, setResellerName] = useState("");
  const [resellerStoreName, setResellerStoreName] = useState("");
  const [resellerSlug, setResellerSlug] = useState("");
  const [resellerWaNumber, setResellerWaNumber] = useState("");
  const [linkReseller, setLinkReseller] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const transformToSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w-]+/g, "") // Remove non-word characters
      .replace(/--+/g, "-") // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ""); // Trim hyphens from the start and end
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Link berhasil disalin");
      })
      .catch((error) => {
        //console.error("Error copying to clipboard:", error)
      });
  };

  useEffect(() => {
    const subdomain = httpHost.split(".")[0];

    const getInitialCatalog = async (storeId) => {
      var urlQuery =
        "/items/Catalog?filter[store_id][_eq]=" +
        storeId +
        "&filter[status][_eq]=published&filter[is_discount][_eq]=1";

      Api.get(urlQuery)
        .then((response) => {
          //console.log(response);
          if (response) {
            setCatalogOriginal(response.data);
            setCatalog(response.data);
            setIsInitialLoading(false);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getStore = async () => {
      Api.get(
        "/items/Store?filter[subdomain][_eq]=" +
          subdomain +
          "&filter[is_activated][_eq]=1&fields=*"
      )
        .then((response) => {
          //console.log(response);
          if (response.data.length == 0) {
            // 404 page
            window.location.href = "/expired";
          }

          if (response) {
            setStore(response.data[0]);
            document.title = response.data[0].name + " - by Kataloqu";
            getInitialCatalog(response.data[0].id);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    setIsInitialLoading(true);
    getStore();
  }, []);

  useEffect(() => {
    const searchCatalog = catalogOriginal.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });

    setCatalog(searchCatalog);
  }, [search]);

  const formatCurrency = (number) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });

    return formatter.format(number);
  };

  const dateFormat = (date = null) => {
    if (date != null && date != undefined) {
      const parts = date.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        return `${day}/${month}/${year}`;
      }
      return date;
    } else {
      return "-";
    }
  };

  const createLinkReseller = async () => {
    setIsLoading(true);
    Api.post("/items/Link", {
      reseller_name: resellerName,
      name: resellerStoreName,
      slug: store.slug + "/" + resellerSlug,
      store_id: store.id,
      wa_number: resellerWaNumber,
    })
      .then((response) => {
        //console.log(response);

        if (response.errors) {
          toast.error("Gagal membuat link reseller, silahkan hubungi admin");
          setIsLoading(false);
          return;
        }

        if (response) {
          setLinkReseller(domain + "/" + store.slug + "/" + resellerSlug);
          toast.success("Link reseller berhasil dibuat");
          setResellerName("");
          setResellerStoreName("");
          setResellerSlug("");
          setResellerWaNumber("");
          closeModal();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Link reseller gagal dibuat");
      });
  };

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  return (
    <div>
      <Helmet>
        <meta name="title" content={store.name} />
        <meta name="description" content={store.name} />
        <meta property="og:title" content={store.name} />
      </Helmet>

      <Header
        onSearch={(e) => {
          setSearch(e);
        }}
        onOpenModal={openModal}
      />

      {isInitialLoading ? (
        <div className="px-4 sm:pt-24 pt-52 pb-4 flex justify-center">
          <ReactLoading type={"balls"} color={"gray"} height={10} width={48} />
        </div>
      ) : (
        <div className="px-4 sm:pt-24 pt-52 pb-4">
          <div className="container mx-auto ">
            <div className="font-bold mb-8 flex flex-row gap-2 items-center">
              Katalog <FaChevronRight /> Diskon
            </div>

            <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
              {catalog.length > 0 &&
                catalog.map((item) => (
                  <Link to={`/detail/${item.id}`}>
                    <div
                      className={`bg-${store.color}-50 rounded overflow-hidden flex flex-col`}>
                      <div>
                        <div className="absolute z-1">
                          {item.is_discount == 1 && (
                            <div
                              className={
                                "bg-red-500 text-white text-xs px-2 py-1 rounded-tr rounded-bl rounded-md font-bold "
                              }>
                              Harga Diskon
                            </div>
                          )}
                        </div>
                        <img
                          src={
                            Config.api_url +
                            "/assets/" +
                            item.image +
                            "?fit=cover&width=400&height=400"
                          }
                          alt="Product 1"
                          className="w-full h-auto"
                        />
                      </div>
                      <div className="p-4 mb-2 sm:h-48 md:h-48 h-40">
                        <h2 className="text-sm sm:text-md font-semibold mt-0">
                          {item.name}
                        </h2>

                        {item.is_discount == 1 ? (
                          <>
                            {item.is_range_price ? (
                              <p className={"text-red-500 text-sm font-normal"}>
                                <span className=" line-through pr-1">
                                  {formatCurrency(item.price_from)} -{" "}
                                  {formatCurrency(item.price_to)}
                                </span>
                                <br />
                                <span
                                  className={
                                    "text-" +
                                    store.color +
                                    "-700 text-sm font-semibold"
                                  }>
                                  {formatCurrency(item.discount_from)} -{" "}
                                  {formatCurrency(item.discount_to)}
                                </span>
                              </p>
                            ) : (
                              <p className={"text-red-500 text-sm font-normal"}>
                                <span className=" line-through pr-1">
                                  {formatCurrency(item.price)}
                                </span>
                                <span
                                  className={
                                    "text-" +
                                    store.color +
                                    "-700 text-sm font-semibold"
                                  }>
                                  {formatCurrency(item.discount)}
                                </span>
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            {item.is_range_price ? (
                              <p
                                className={
                                  "text-" +
                                  store.color +
                                  "-700 text-sm font-semibold"
                                }>
                                {formatCurrency(item.price_from)} -{" "}
                                {formatCurrency(item.price_to)}
                              </p>
                            ) : (
                              <p
                                className={
                                  "text-" +
                                  store.color +
                                  "-700 text-sm font-semibold"
                                }>
                                {formatCurrency(item.price)}
                              </p>
                            )}
                          </>
                        )}

                        {item.is_preorder == 1 && (
                          <>
                            <p className="text-gray-500 text-xs mt-2 ">
                              {item.preorder_additional_text}
                            </p>
                            <p className={"text-xs mt-2 "}>
                              <span
                                className={
                                  "bg-" +
                                  store.color +
                                  "-500 text-white px-2 py-1 rounded "
                                }>
                                {dateFormat(item.preorder_closed_date)}
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-30">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-8 z-50 mx-4">
            <div className="w-full justify-end flex p-0 ">
              <button
                className="-mt-6 mb-4 -mr-6 p-0 text-xl"
                onClick={closeModal}>
                <FaWindowClose className="text-red-500 bg-white" />
              </button>
            </div>
            <h2 className="text-xl font-bold mb-4">Buat link reseller Anda</h2>
            <p className="text-sm mb-2">Nama reseller</p>
            <input
              type="text"
              className="border-2 rounded-lg w-full px-3 py-2"
              value={resellerName}
              onChange={(e) => {
                setResellerName(e.target.value);
              }}
            />
            <p className="text-sm mb-2">Nama toko</p>
            <input
              type="text"
              className="border-2 rounded-lg w-full px-3 py-2"
              value={resellerStoreName}
              onChange={(e) => {
                setResellerStoreName(e.target.value);
                setResellerSlug(transformToSlug(e.target.value));
              }}
            />
            <p className="text-sm mb-2 mt-2">Nomor Whatsapp</p>
            <input
              type="text"
              className="border-2 rounded-lg w-full px-3 py-2"
              value={resellerWaNumber}
              onChange={(e) => {
                setResellerWaNumber(e.target.value);
              }}
            />
            <p className="text-sm mt-4 ">Alamat link reseller Anda</p>
            <p
              className={
                "text-sm mb-2 font-bold text-" + store.color + "-500 "
              }>
              {domain}/{store.slug}/{resellerSlug}
            </p>

            <div className="flex gap-2 flex-row justify-between">
              <button
                onClick={() =>
                  handleCopy(domain + "/" + store.slug + "/" + resellerSlug)
                }
                className="mt-4 bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded">
                <FaCopy className="text-gray-500" />
              </button>

              {isLoading ? (
                <ReactLoading
                  type={"balls"}
                  color={"gray"}
                  height={10}
                  width={48}
                />
              ) : (
                <button
                  onClick={createLinkReseller}
                  className={
                    "mt-4 bg-" +
                    store.color +
                    "-500 hover:bg-" +
                    store.color +
                    "-700 text-white font-bold py-2 px-4 rounded"
                  }>
                  Buat link reseller
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <CartFloat store={store} />

      <ToastContainer />
    </div>
  );
}

export default CatalogSale;
