import React, { useState, useEffect } from "react";

import Api from "../../../services/Api";

import Topmenu from "../../../components/adminqu/Topmenu";
import { ToastContainer, toast } from "react-toastify";
import Config from "../../../config/Config";

import ReactLoading from "react-loading";

import { Link } from "react-router-dom";

import SideNav from "../../../components/adminqu/Sidenav";

function AdminquSetting() {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [authUser, setAuthUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    store_id: {
      name: "",
      subdomain: "",
      package: "",
    },
  });

  const [store, setStore] = useState({
    color: "pink",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    const getUser = async (token) => {
      setIsLoading(true);
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);
          setStore(response.data.store_id);

          // if (response.data.store_id.color == "") {
          //   setStore((store) => ({ ...store, color: "pink" }));
          // }

          setIsLoading(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    getUser(token);
  }, []);

  const uploadLogo = (event) => {
    const image = event.target.files[0];

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var formdata = new FormData();
    formdata.append("file", image, image.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(Config.api_url + "/files", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        //console.log(result);
        const data = JSON.parse(result).data;

        setStore((store) => ({ ...store, logo: data.id }));
      })
      .catch((error) => {
        //console.log("error", error)
      });
  };

  const submitSave = async () => {
    setIsLoading(true);

    Api.patch(
      "/items/Store/" + store.id,
      {
        name: store.name,
        whatsapp_number: store.whatsapp_number,
        color: store.color,
        auto_active_link: store.auto_active_link,
        logo: store.logo,
      },
      token
    ).then((response) => {
      if (response.errors) {
        toast.error(
          "Gagal menyimpan pengaturan  (" + response.errors[0].message + " )"
        );
        setIsLoading(false);
        return;
      }

      if (response) {
        toast.success("Pengaturan berhasil disimpan");
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <SideNav page="setting" />
        </div>
        <div className="flex-1">
          <div className="flex-1 w-full  p-4 py-5">
            <div className="px-4 pl-16 sm:pl-4 font-bold">Pengaturan</div>
            <div className="pt-4 lg:pt-4">
              <div className="my-8 mb-20 flex flex-row justify-center gap-2">
                <button className="bg-pink-500 p-2 px-4 rounded ">
                  <span className="text-white text-sm">Informasi</span>
                </button>

                <Link
                  to="/adminqu/setting/order"
                  className="bg-gray-200 p-2 px-4 rounded">
                  <span className="text-gray-900 text-sm">Order</span>
                </Link>
                <Link
                  to="/adminqu/setting/shipping"
                  className="bg-gray-200 p-2 px-4 rounded">
                  <span className="text-gray-900 text-sm">Ekspedisi</span>
                </Link>
              </div>
              <div className="w-full flex flex-row justify-center px-4">
                <div className="w-full lg:w-1/2 bg-gray-50 rounded p-8 mt-8 md:mt-0">
                  <div className="my-1 flex flex-col md:flex-row gap-2">
                    <div className="my-1 w-full font-semibold items-center gap-4 flex justify-center md:justify-start -mt-20  ">
                      <label
                        for="file"
                        className="w-28 h-28 rounded-full bg-gray-100 overflow-hidden border border-white border-8 cursor-pointer">
                        <img
                          src={
                            store.logo != null
                              ? Config.api_url + "/assets/" + store.logo
                              : "https://via.placeholder.com/400x400?text=Logo"
                          }
                        />
                      </label>
                      <input
                        type="file"
                        id="file"
                        className="hidden"
                        onChange={uploadLogo}
                      />
                    </div>
                  </div>
                  <div className="my-1 flex flex-col md:flex-row gap-2">
                    <div className="my-1 w-full md:w-1/2 font-semibold">
                      <span className="text-sm">Nama Toko</span>
                      <input
                        type="text"
                        className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                        value={store.name}
                        onChange={(e) => {
                          setStore((store) => ({
                            ...store,
                            name: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="my-1 w-full md:w-1/2 font-semibold">
                      <span className="text-sm">Nomor whatsapp</span>
                      <input
                        type="tel"
                        className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                        value={store.whatsapp_number}
                        onChange={(e) => {
                          setStore((store) => ({
                            ...store,
                            whatsapp_number: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="my-1 flex flex-col md:flex-row gap-2">
                    <div className="my-1 w-full font-semibold">
                      <span className="text-sm">Warna tema</span>
                      <span className={"text-sm text-" + store.color + "-500"}>
                        {" "}
                        :{" "}
                        {store.color.charAt(0).toUpperCase() +
                          store.color.slice(1)}
                      </span>

                      <div className="flex flex-row mt-2 gap-2">
                        <div
                          className={
                            store.color == "gray"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-gray-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-gray-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "gray",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "red"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-red-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-red-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "red",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "orange"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-orange-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-orange-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "orange",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "amber"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-amber-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-amber-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "amber",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "yellow"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-yellow-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-yellow-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "yellow",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "lime"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-lime-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-lime-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "lime",
                            }))
                          }></div>
                      </div>
                      <div className="flex flex-row mt-2 gap-2">
                        <div
                          className={
                            store.color == "green"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-green-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-green-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "green",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "emerald"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-emerald-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-emerald-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "emerald",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "teal"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-teal-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-teal-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "teal",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "cyan"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-cyan-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-cyan-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "cyan",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "sky"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-sky-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-sky-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "sky",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "blue"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-blue-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-blue-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "blue",
                            }))
                          }></div>
                      </div>
                      <div className="flex flex-row mt-2 gap-2">
                        <div
                          className={
                            store.color == "indigo"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-indigo-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-indigo-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "indigo",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "violet"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-violet-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-violet-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "violet",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "purple"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-purple-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-purple-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "purple",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "fuchsia"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-fuchsia-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-fuchsia-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "fuchsia",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "pink"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-pink-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-pink-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "pink",
                            }))
                          }></div>
                        <div
                          className={
                            store.color == "rose"
                              ? "w-10 h-10 cursor-pointer rounded-full bg-rose-500 border border-white border-8 border-double"
                              : "w-10 h-10 cursor-pointer rounded-full bg-rose-500"
                          }
                          onClick={() =>
                            setStore((store) => ({
                              ...store,
                              color: "rose",
                            }))
                          }></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  w-full mb-4 mt-4">
                    <div className="font-semibold text-sm">
                      Aktifkan otomatis link reseller?{" "}
                    </div>
                    <label
                      for="toggleB"
                      className="flex items-center cursor-pointer">
                      <div className="relative">
                        <input
                          type="checkbox"
                          id="toggleB"
                          className="sr-only"
                          checked={store.auto_active_link}
                          onChange={(e) =>
                            setStore((store) => ({
                              ...store,
                              auto_active_link: e.target.checked,
                            }))
                          }
                        />
                        <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                      </div>
                    </label>
                  </div>
                  <div className="text-right">
                    {isLoading ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        className="mt-4 w-full md:w-auto bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded-lg"
                        onClick={() => {
                          submitSave();
                        }}>
                        Simpan pengaturan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminquSetting;
